import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { addMonths, differenceInCalendarMonths } from 'date-fns'
import { get } from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

import useVerifyRole from '../../hooks/verifyRole'
import SEO from '../../components/SEO'
import { Form } from '../../_shared/components'

const reports = [
  { value: 'sales', name: 'Gavekort solgt i periode' },
  { value: 'outstanding', name: 'Utestående gavekort' },
  { value: 'redeemed', name: 'Gavekort innløst i periode' },
  { value: 'expired', name: 'Utløpte gavekort' },
]

const periodOptions = report => {
  if (['expired'].indexOf(report) > -1) {
    const thisYear = new Date().getFullYear()
    return new Array(thisYear - 2020 + 1).fill(null).map((v, i) => {
      const year = thisYear - i
      return { value: year, name: year }
    })
  }

  // Date foodsteps launched
  const startTime = new Date('2020-05-10')
  const months = differenceInCalendarMonths(new Date(), startTime) + 1

  return new Array(months).fill(null).map((v, i) => {
    const date = addMonths(startTime, months - (i + 1))
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return {
      value: `${year}-${month}`,
      name: `${month < 10 ? `0${month}` : month}.${year}`,
    }
  })
}

export default function Page() {
  const { loading, hasRole } = useVerifyRole(['admin'])
  const [report, setReport] = useState(get(reports, '[0].value'))
  const [period, setPeriod] = useState(
    get(periodOptions(get(reports, '[0].value')), '[0].value'),
  )

  const { refetch: getReport } = useQuery(
    gql`
      query GetReport($report: Report!, $year: Int!, $month: Int) {
        report(report: $report, year: $year, month: $month) {
          mimeType
          base64
        }
      }
    `,
    { fetchPolicy: 'network-only', skip: true },
  )

  if (loading || !hasRole) {
    return null
  }

  const handleFormSubmit = async e => {
    e.preventDefault()

    const [, year, month = 1] =
      String(period).match(/(\d{4})(?:-(\d{1,2}))?/) || []

    const { data: reportData } = await getReport({
      report,
      year: Number(year),
      month: Number(month),
    })

    if (!reportData) {
      return
    }
    const link = document.createElement('a')

    link.download = `${report}.csv`
    link.href = `data:${reportData.report.mimeType};base64,${reportData.report.base64}`
    link.click()

    setTimeout(() => link.remove(), 1000)
  }

  const GENERATE = {
    title: 'Lag rapport',
    items: [
      {
        item: 'select',
        key: 'reports',
        label: 'Rapport',
        options: reports,
        value: report,
        onChange: setReport,
      },
      {
        item: 'select',
        key: 'period',
        label: 'Periode',
        options: periodOptions(report),
        value: period,
        onChange: setPeriod,
      },
    ],
    onSubmit: handleFormSubmit,
    submit: 'Generér og last ned',
  }

  return (
    <>
      <SEO title="Rapporter" />
      <Form
        step="generate"
        navigation
        handleBack={() => navigate('/admin')}
        steps={{
          generate: GENERATE,
        }}
      />
    </>
  )
}
